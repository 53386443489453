import { useState, useEffect } from 'react';
import {
    Wrapper,
    useUiExtension,
    FieldExtensionType,
    FieldExtensionFeature,
} from '@graphcms/uix-react-sdk';
import { CKEditor } from 'ckeditor4-react';

const config = {
    startupMode: 'source',
    allowedContent: true,
    autoParagraph: false,
    fillEmptyBlocks: false,
    entities: false,
    basicEntities: false,
    removeFormatAttributes:'',
    extraAllowedContent: '*(*);*{*}',
    htmlEncodeOutput: false,
    ignoreEmptyParagraph: false,
    toolbar: [], // Remove the toolbar
};
const HtmlField = () => {
    const { value, onChange } = useUiExtension();
    const [localValue, setLocalValue] = useState(value || '');
  
    useEffect(() => {
      onChange(localValue);
    }, [localValue, onChange]);
  
    return (
    <div className="html-field">
        <CKEditor
            initData={value}
            config={config}
            onChange={ ( event ) => {
                console.log(event.editor.getData());
                setLocalValue(event.editor.getData());
            } }
        />
    </div>
    );
};

const declaration = {
    extensionType: 'field',
    fieldType: FieldExtensionType.STRING,
    features: [FieldExtensionFeature.FieldRenderer],
    name: 'Html Field',
  };

const HtmlFieldComponent = () => {
  return (
    <Wrapper declaration={declaration}>
      <HtmlField />
    </Wrapper>
  );
};

export default HtmlFieldComponent;